// Imports
import Vue from "vue";
import Router from "vue-router";
import store from "@/store";
import authenticate from "@/auth/authenticate";
import admin from "@/auth/admin";

Vue.use(Router);

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior: (to, from, savedPosition) => {
        if (to.hash) return { selector: to.hash };
        if (savedPosition) return savedPosition;

        return { x: 0, y: 0 };
    },
    routes: [
        {
            path: "/",
            component: () => import("@/views/app/Index"),
            beforeEnter: authenticate,
            redirect: "/app/pages/Home",

            children: [
                // {
                //   path: "layout/:layout",
                //   component: () => import("@/views/app/Index")
                // },

                {
                    path: "/app/pages",
                    component: () => import("@/views/app/pages/Pages"),
                    children: [
                        {
                            path: "home",
                            component: () => import("@/views/app/pages/Home")
                        },
                        {
                            path: "post/:url",
                            component: () => import("@/views/app/pages/PostView")
                        },
                        /* {
                          path: "purposes/create",
                          name: "PurposeCreate",
                          component: () => import("@/views/app/pages/PurposeCreate")
                      },
                       {
                          path: "purposes",
                          component: () => import("@/views/app/pages/PurposesView")
                      },
                      {
                          path: "myPurposes",
                          component: () => import("@/views/app/pages/MyPurposeView")
                      }, */
                        {
                            path: "profile",
                            component: () => import("@/views/app/pages/Profile")
                        },
                        {
                            path: "termsAccepted",
                            component: () => import("@/views/app/pages/TermsAccepted")
                        },
                        {
                            path: "legalBasesAccepted",
                            component: () => import("@/views/app/pages/LegalBasesAccepted")
                        },
                        {
                            path: "gifts",
                            component: () => import("@/views/app/pages/MarketPlace")
                        },
                        {
                            path: "statistics",
                            component: () => import("@/views/app/pages/StatisticsDetails")
                        },
                        {
                            path: "recognize/create",
                            name: "RecognitionCreate",
                            component: () => import("@/views/app/pages/recognitions/RecognitionCreate")
                        },
                        {
                            path: "recognize",
                            component: () => import("@/views/app/pages/recognitions/MyRecognitionsView")
                        },
                        {
                            path: "wasNominated",
                            component: () => import("@/views/app/pages/Nominated")
                        },
                        {
                            path: "votation",
                            name: "VoteRecognitionsView",
                            component: () => import("@/views/app/pages/recognitions/VoteRecognitionsView")
                        },
                        {
                            path: "recognitionsPersonType",
                            name: "SeeRecognitionsPersonType",
                            component: () => import("@/components/votesRecognitions/seeRecognitionsPersonType")
                        },
                        {
                            path: "winners",
                            name: "Winners",
                            component: () => import("@/views/app/pages/Winners")
                        }
                        /* {
                                                path: "juegoMisiones",
                                                component: () => import("@/views/app/pages/juegoMisiones")
                                            },
                                            {
                                                path: "juegoFinal",
                                                component: () => import("@/views/app/pages/juegoDespedida")
                                            },
                                            {
                                                path: "companyMissions",
                                                component: () => import("@/views/app/pages/CompanyMissions")
                                            }, */
                        /*,
                        {
                            path: "awards",
                            component: () => import("@/views/app/pages/Awards"),
                            children: [
                                {
                                    path: "/",
                                    component: () => import("@/components/awards/winner")
                                },
                                {
                                    path: "areas",
                                    component: () => import("@/components/awards/areasList")
                                },
                                {
                                    path: ":url",
                                    component: () => import("@/components/awards/winnerByArea")
                                },
                                {
                                    path: "ranking/:url",
                                    component: () => import("@/components/awards/rankingArea")
                                }
                            ]
                        } */

                    ]
                }
            ]
        },
        /* {
            path: "/app/pages/juegoMisiones",
            beforeEnter: authenticate,
            component: () => import("@/views/app/pages/juegoMisiones")
        }, */
        {
            path: "/app/admin",
            component: () => import("@/views/app/IndexAdmin"),
            beforeEnter: admin,

            children: [
                {
                    path: "/",
                    component: () => import("@/views/app/pages/Pages"),
                    redirect: "/app/admin/dashboard",
                    children: [
                        {
                            path: "dashboard",
                            component: () => import("@/views/app/pages/admin/DashBoard")
                        },
                        {
                            path: "users",
                            component: () => import("@/views/app/pages/admin/Users")
                        },
                        {
                            path: "roles",
                            component: () => import("@/views/app/pages/admin/Roles")
                        },
                        {
                            path: "posts/create",
                            name: "PostsCreate",
                            component: () => import("@/views/app/pages/admin/PostsCreate")
                        },
                        {
                            path: "posts",
                            component: () => import("@/views/app/pages/admin/Posts")
                        },
                        {
                            path: "articles/create",
                            name: "ArticleCreate",
                            component: () => import("@/views/app/pages/admin/ArticlesCreate")
                        },
                        {
                            path: "articles",
                            component: () => import("@/views/app/pages/admin/Articles")
                        },
                        {
                            path: "groups",
                            component: () => import("@/views/app/pages/admin/Groups")
                        },
                        {
                            path: "words",
                            component: () => import("@/views/app/pages/admin/Words")
                        },
                        {
                            path: "terms/create",
                            name: "TermsCreate",
                            component: () => import("@/views/app/pages/admin/TermsCreate")
                        },
                        {
                            path: "terms",
                            component: () => import("@/views/app/pages/admin/Terms")
                        },
                        {
                            path: "configs",
                            component: () => import("@/views/app/pages/admin/Configs")
                        },
                        {
                            path: "recognitions",
                            name: "Recognitions",
                            component: () => import("@/views/app/pages/admin/Recognitions")
                        }
                    ]
                }
            ]
        },
        {
            path: "/app/sessions",
            component: () => import("@/views/app/sessions/Sessions"),

            children: [
                /* {
                    path: "sign-in",
                    component: () => import("@/views/app/sessions/SignIn")
                }, */
                {
                    path: "error",
                    component: () => import("@/views/app/sessions/Error")
                },
                {
                    path: "forgot",
                    component: () => import("@/views/app/sessions/Forgot")
                },
                {
                    path: "pass-reset",
                    component: () => import("@/views/app/sessions/NewPassword")
                },
                /* {
                    path: "lockscreen",
                    component: () => import("@/views/app/sessions/Lockscreen")
                }, */
                {
                    path: "sign-in-two",
                    name: "login",
                    component: () => import("@/views/app/sessions/SignInUsernameCode")
                },
                /* {
                    path: "sign-in-three",
                    component: () => import("@/views/app/sessions/SignInThree")
                },
                {
                    path: "sign-in-four",
                    component: () => import("@/views/app/sessions/SignInFour")
                },
                {
                    path: "sign-in-five",
                    component: () => import("@/views/app/sessions/SignInFive")
                },
                {
                    path: "sign-up",
                    component: () => import("@/views/app/sessions/SignUp")
                }, */
                {
                    path: "sign-up-2",
                    name: "signup",
                    component: () => import("@/views/app/sessions/SignUpTwo")
                }/*,
                {
                    path: "sign-up-5",
                    component: () => import("@/views/app/sessions/SignUpFive")
                } */
            ]
        },
        {
            // will match everything (use for not found route error:404)
            path: '*',
            redirect: "/app/pages/Home"
        }
    ]
});

router.beforeEach((to, from, next) => {
    // If this isn't an initial page load.
    if (to.path) {
    // Start the route progress bar.
        store.dispatch("changeThemeLoadingState", true);
    }
    next();
});

router.afterEach(() => {
    // Remove initial loading
    // const gullPreLoading = document.getElementById("loading_wrap");
    // if (gullPreLoading) {
    //   gullPreLoading.style.display = "none";
    // }
    // Complete the animation of the route progress bar.
    setTimeout(() => store.dispatch("changeThemeLoadingState", false), 500);
    // NProgress.done();
    // if (isMobile) {
    // if (window.innerWidth <= 1200) {
    //   // console.log("mobile");
    //   store.dispatch("changeSidebarProperties");
    //   if (store.getters.getSideBarToggleProperties.isSecondarySideNavOpen) {
    //     store.dispatch("changeSecondarySidebarProperties");
    //   }
    //   if (store.getters.getCompactSideBarToggleProperties.isSideNavOpen) {
    //     store.dispatch("changeCompactSidebarProperties");
    //   }
    // } else {
    //   if (store.getters.getSideBarToggleProperties.isSecondarySideNavOpen) {
    //     store.dispatch("changeSecondarySidebarProperties");
    //   }
    //   // store.state.sidebarToggleProperties.isSecondarySideNavOpen = false;
    // }
});

export default router;
